@import "../util/globals";
.report {
    flex: 1;
    display: flex;
    margin-bottom: 15px;
    .accordion {
        margin-top: 0 !important;
        .content {
            padding-top: 0 !important; } }
    .progressInput {
        max-width: 100px;
        min-width: 60px !important; }
    .buttonTable {
        margin-top: 5px;
        margin-bottom: 5px; }
    .floatingUpdate {
        padding: 8px 15px;
        font-size: 1.2rem;
        background-color: $blue;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        box-shadow: 2px 2px 5px $gray;
        margin-right: 0;
        color: #FFF;
        position: fixed;
        right: 0;
        top: 50%;
        z-index: 1;
        &:hover {
            box-shadow: 2px 2px 5px $gray;
            background-color: $blueFocused;
            color: #FFF; }
        &:active {
            box-shadow: 0 0 0 $gray; }
        &:focus {
            background-color: $blueFocused;
            color: #FFF; } }
    .filterSection {
        margin-top: 15px;
        border-right-style: solid;
        border-right-color: $lightGray;
        border-right-width: 1px;
        width: 15%;
        @media screen and (max-width: 1130px) {
            // max-width: 0
            // overflow: hidden
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 100%;
            max-width: 400px;
            z-index: 2;
            background-color: #FFF; } }
    .filterSectionDeactivated {
        @media screen and (max-width: 1130px) {
            width: 0;
            overflow: hidden;
            border-right-width: 0; } }
    .filterSectionButtons {
        @media screen and (min-width: 1131px) {
            display: none; } }
    .listContainer {
        button {
            padding: 6px 21px; } }
    .reportContent {
        //display: flex !important
 }        //flex-direction: column
    .tableStyle {
        border-collapse: collapse;
        width: 100%;
        th {
            position: sticky;
            top: 0;
            z-index: 1; } }
    .tableGrid {
        //flex: 1 1 auto
        display: flex;
        overflow-y: auto;
        margin: 1rem !important; }
    .tableColumn {
        height: 100%;
        flex: 1 1 auto;
        padding: 0 !important; }

    .smallButton {
        padding: .5em;
        font-size: .8rem;
        width: 85px; }
    .marginButton {
        margin-left: 103px; } }


.detailsContainer {
    display: flex;
    div {
        padding: 0 1rem; }
    .subtitle {
        color: $gray; } }


.evidenceTabFlex {
    & > div {
        display: flex;
        flex-direction: column;
        & > .grid, & > .linkList {
            flex: 1; } } }
