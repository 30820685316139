@import '../util/globals';

.addArea {
    margin-top: auto !important;
    margin-bottom: auto !important;

    form {
        label {
            font-size: 24px !important;
            text-align: center;
            font-weight: lighter !important;
            margin-bottom: 10px !important;
            color: $gray !important; }

        input, textarea, .selection {
            border-style: unset !important;
            box-shadow: 0 0 6px $shadow !important;
            &::placeholder {
                color: $gray !important; }
            .default {
                color: $gray !important; } } } }

.textDescription {
    text-align: justify;
    font-size: 1.1rem;
    margin-top: 40px; }
