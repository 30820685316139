.practiceSection {
    .deleteIcon {
        font-size: 1.28em;
        margin-right: 15px; }

    .dashSubtitle {
        .deleteIcon {
            font-size: .7em !important; } }

    .noProjects {
        display: flex;
        h2 {
            margin-right: 10px; } } }

