@import "./components/util/globals";
#root {
	width: 100%;
	flex: 1;
	display: flex;
	max-height: 100%; }
.App {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	flex: 1;
	display: flex;
	&> div {
		flex: 1; }
	.formStyle {
		label {
			font-size: 24px !important;
			text-align: center;
			font-weight: lighter !important;
			margin-bottom: 10px !important;
			color: $gray !important; }

		input, textarea, .selection {
			border-style: unset !important;
			box-shadow: 0 0 6px $shadow !important; }
		&::placeholder {
			color: $gray !important; }
		.default {
			color: $gray !important; }
		button {
			background-color: $blue !important;
			color: #fff !important;
			font-size: 1.5em !important;
			font-weight: 900 !important;
			border-radius: 10px !important;
			width: 100%;
			max-width: 200px; } } }
.alignMiddle {
	margin-top: auto !important;
	margin-bottom: auto !important; }

.dashTitle {
	color: $gray;
	font-size: 40px;
	font-weight: 900; }

.mainIcon {
	color: $blue !important;
	font-size: 40px !important;
	margin-top: 17px !important; }

.dashSubtitle {
	display: inline-block;
	margin-bottom: 0 !important;
	span {
		font-size: .75em;
		margin-left: 30px;
		font-weight: normal; } }

.sectionsStyle {
	border-color: $blue;
	border-style: solid;
	border-width: 3px;
	padding: 1px 15px;
	text-align: center;
	color: $blue;
	font-size: 20px;
	font-weight: bolder;
	border-radius: 25px;
	&:hover {
		color: #FFF;
		background-color: $blue;
		cursor: pointer; } }

.activeSectionsStyle {
	color: #FFF;
	background-color: $blue;
	cursor: pointer; }

.buttonWhiteBlue {
	background-color: #FFF !important;
	border-color: $blue !important;
	border-style: solid !important;
	border-width: 2px !important;
	color: $blue !important;
	padding-top: 5px !important;
	padding-bottom: 5px !important;
	&:hover {
		background-color: $blue !important;
		color: #FFF !important; } }

.buttonBlueWhite {
	background-color: $blue !important;
	border-color: $blue !important;
	border-style: solid !important;
	border-width: 2px !important;
	color: #FFF !important;
	padding-top: 5px !important;
	padding-bottom: 5px !important;
	&:hover {
		background-color: #FFF !important;
		color: $blue !important; } }

.BWBActive {
	background-color: $blue !important;
	color: #FFF !important; }

.buttonWhiteBlack {
	background-color: #FFF !important;
	border-color: #000 !important;
	border-style: solid !important;
	border-width: 2px !important;
	color: #000 !important;
	padding-top: 5px !important;
	padding-bottom: 5px !important;
	&:hover {
		background-color: #000 !important;
		color: #FFF !important; } }

.buttonBlue {
	background-color: $blue !important;
	color: #fff !important;
	border-radius: 10px !important; }

.buttonRed {
	background-color: $red !important;
	color: #fff !important;
	border-radius: 10px !important; }

.buttonDarkGray {
	background-color: $darkGray !important;
	color: #fff !important;
	border-radius: 10px !important; }

.setLabelLeft {
	display: flex;
	label {
		align-self: center; } }

.leftAlign {
	margin-left: auto !important;
	width: fit-content; }
.userAvatarImg {
	width: 50px;
	height: 50px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	border-radius: 100%; }

.bottomLineInput {
	.dropdown, input {
		border-top-width: 0 !important;
		border-left-width: 0 !important;
		border-right-width: 0 !important;
		border-radius: 0 !important;
		border-color: #000 !important;
		//color: $blue !important
		box-shadow: none !important;
		background-color: rgba(255, 255, 255, 0) !important; }
	.menu {
		border-color: $gray !important;
		border-radius: 0 !important;
		color: $blue !important;
		width: fit-content !important;
		.item {
 } } }			//width: fit-content
.setFlex {
	display: flex !important; }

.setFlexColumn {
	display: flex !important;
	flex-direction: column; }

.setFlex1 {
	flex: 1; }

.setFlex10 {
	flex: 1 0; }

.firstChildFlex {
	& > div {
		display: flex;
		flex-direction: column;
		flex: 1; } }

.overflowY {
	overflow-y: auto; }

.marginAuto {
	margin: auto; }

.marginRight {
	margin-right: 15px !important; }

.deleteIcon {
	font-size: 1.28em !important;
	margin-right: 15px !important; }

.setFullFlex {
	display: flex;
	flex-direction: column !important;
	flex: 1;
	background-size: cover;
	background-repeat: no-repeat; }

.verticalAlignFlex {
	margin-top: auto !important;
	margin-bottom: auto !important; }

.marginLeft {
	margin-left: 15px !important; }

.marginBot {
	margin-bottom: 15px; }

.titleDecorationBar {
	height: 8px;
	width: 200px;
	background-color: $blue;
	margin-top: 25px; }

.grayButton {
	color: #FFF !important;
	background-color: $darkGray !important;
	font-size: 1.2rem !important;
	font-weight: bolder; }

.alignSelfCenter {
	align-self: center; }

.whiteColor {
	color: #FFF !important; }

.centerText {
	text-align: center !important; }

.rightText {
	text-align: right; }

.justifiedText {
	text-align: justify; }

.fullWidth {
	width: 100%; }

.modalP {
	.okButton {
		background-color: $blue !important;
		color: #FFF !important;
		border-radius: 20px !important;
		min-width: 100px; } }

.ganttContainer {
	width: 100%;
	overflow-x: scroll; }

.ganttMax {
	overflow: hidden; }

.marginTop {
	margin-top: 15px; }

.notificationDiv {
	color: #FFF !important;
	padding-top: 10px !important;
	padding-bottom: 10px !important;
	.sub {
		color: #FFF !important; } }

.deleteIcon {
	cursor: pointer;
	&:hover {
		color: $red; } }

.sideBorders {
	border-left-style: solid;
	border-right-style: solid;
	border-left-color: $lightGray;
	border-right-color: $lightGray; }

.marginBottom {
	margin-bottom: 15px !important; }

.removeStyles {
	all: "initial"; }
// .rightBorder
// 	border-right-style: solid
// 	border-right-color: $lightGray

.verticalAlignMiddle {
	vertical-align: middle !important; }

.erasePadding {
	padding: .4em 1.1em !important; }

h2 {
	color: $gray; }


.setPointer {
	cursor: pointer; }

.centerDivFlex {
	margin-left: auto;
	margin-right: auto; }

.fullBackground {
	position: fixed;
	background-color: rgba(255, 255, 255, 0);
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 5; }

.openMobileMenuBar {
	z-index: 8; }

.topBarMobileMenu {
	padding: 15px;
	position: absolute;
	right: 0;
	background-color: $blue2;
	color: #FFF;
	width: 100%;
	max-width: 500px;
	//height: 100%
	margin-right: 0;
	z-index: 10;
	@media screen and (max-width: 435px) {
		padding: 8px; }
	.selectedMobileMenu {
		background-color: #FFF;
		color: $blue2;
		svg {
			path {
				fill: $blue2 !important; }
			ellipse {
				fill: $blue2 !important; } } }
	.headerUser {
		margin-left: 15px;
		margin-right: 15px; }
	ul {
		padding: 0;
		margin: 0;
		list-style-type: none;
		li {
			padding: 10px 15px;
			cursor: pointer;
			user-select: none;
			border-style: solid;
			border-width: 0 0 0 0;
			//border-color: rgba(34,36,38,.1)
			&::before {
				position: absolute;
				content: '';
				top: 0;
				left: 0;
				width: 100%;
				height: 1px;
 }				//background: rgba(34,36,38,.1)
			.elementContainer {
				display: flex;
				.svgContainer {
					display: flex;
					fill: red !important;
					svg {
						width: 30px;
						height: 30px;
						@media screen and (max-width: 435px) {
							width: 25px;
							height: 25px; }
						path {
							fill: #FFF; }
						ellipse {
							fill: #FFF; } } }
				.elementContent {
					flex: 1;
					margin-left: 20px;
					font-size: 1.5rem;
					font-weight: 800;
					display: flex;
					span {
						margin-top: auto;
						margin-bottom: auto; }
					@media screen and (max-width: 435px) {
						font-size: 1.2rem; } } } } } }
.noPadding {
	padding: 0 !important; }

.noVerticalPadding {
	padding-top: 0 !important;
	padding-bottom: 0 !important; }

.hitoIcon {
	width: 10px;
	fill: $blue; }
//.switchBlue
.checked {
	label {
		&::before {
			background-color: $blue !important; }
		&::after {
			color: #FFF !important; } } }

.lengthIndicator {
	p {
		text-align: right;
		font-size: .9rem; }
	.field {
		margin: 2px 0 0 0 !important; }
	.fieldError {
		color: red; } }

.commentsContainer {
	.comments {
        max-width: none !important; } }

.customModalHeight {
	height: 40vh; }

.modalHeight {
	height: 58vh; }

// .FMClass
// 	height: 60vh
// 	& > div
// 		height: 95%

.FMClass {
	height: 60vh;
	display: flex !important;
	flex-direction: column;
	& > div {
		flex: 1; } }

.fullHeight {
	height: 100%; }

.flexOverflow {
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: 0;
	overflow-y: auto; }

.paddingLeft {
	padding-left: 15px; }

.paddingRight {
	padding-right: 15px; }

.paddingTop {
	padding-top: 15px; }

.paddingBottom {
	padding-bottom: 15px; }

.quillContainer {
	& > div {
		display: flex;
		flex-direction: column;
		height: 100%;
		.ql-container {
			flex-grow: 1;
			flex-shrink: 1;
			flex-basis: 0;
			overflow-y: auto; } } }

.editableTitleContainer {
	input {
		text-align: center !important;
		font-size: 20px !important; } }

.noMargin {
	.fileManager {
		margin: 0 !important; } }

.noMargin {
	margin: 0 !important; }

.noPadding {
	padding: 0 !important; }

.noPaddingLeft {
	padding-left: 0 !important; }

.noPaddingRight {
	padding-right: 0 !important; }

.frameworkButton {
	font-size: .9rem !important; }
