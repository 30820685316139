@import "../util/globals";

.editFramework {
    max-height: calc(70vh);
    min-height: calc(30vh);
    display: flex;
    //flex-direction: column

    .dateInput {
        white-space: normal; }

    .contentContainer {
        padding-right: 15px; }
    .quillContainer {
        min-height: 250px;
        display: flex;
        background-color: #FFF;
        padding: 0 15px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        flex-direction: column;
        .quill {
            display: flex;
            flex-direction: column;
            flex: 1;
            .ql-container {
                flex: 1; } } }
    .descriptionDetails {
        max-height: 250px;
        overflow-y: auto;
        margin-bottom: 15px;
        img {
            max-width: 100%; } }
    .buttonContainer {
        text-align: center;
        .wideButton {
            min-width: 150px;
            max-width: 100%;
            margin: 0 1em; } }

    .editTitle {
        width: 100%;
        // text-align: center !important
        input {
            text-align: center;
            font-size: 1.7rem !important;
            color: $gray !important; } }

    .inputError {
        .ql-container {
            background-color: #fff6f6;
            border-color: #e0b4b4; } }

    .errorLabel {
        background-color: #fff !important;
        border-color: #e0b4b4 !important;
        color: #9f3a38 !important; }

    .Collapsible__trigger {
        cursor: pointer;
        background-color: $blue;
        padding-right: 10px;
        h2 {
            color: #FFF;
            user-select: none; } }

    .is-closed {
        h2:after {
            content: '\002B';
            float: right; } }

    .is-open {
        h2:after {
            content: "\2212";
            float: right; } }
    .quill {
        margin-bottom: 15px;
        .ql-editor {
            max-height: 200px; } }
    // .SPCollapsible
    //     cursor: pointer
    //     border: none
    //     outline: none

    // .SPActive .SPCollapsible
    //     background-color: $blue
    //     color: #FFF

    // .SPCollapsible:after
    //     content: '\002B'
    //     //color: #FFF
    //     font-weight: bold
    //     float: right
    //     margin-left: 5px

    // .SPActive:after
    //     content: "\2212"

    // .SPCollapsibleContent
    //     max-height: 0
    //     overflow: hidden
    //     transition: max-height 2s ease-out

    // .SPCollapsibleContentActive
 }    //     max-height: none
