@import "../globals";
.linkEvidence {
    display: flex;
    flex: 1;
    & > div {
        flex: 1; }
    .checkBoxContainer {
        margin-right: 5px;
        margin-top: 5px; }
    .linkListContainer {
        padding-right: 15px; }
    .selectedItem {
        background-color: $blueFocused !important;
        color: #FFF !important;
        div {
            color: #FFF !important; } }
    .clickableItem {
        cursor: pointer;
        .imageContainer {
            padding-top: 5px !important; }
        &:hover {
            background-color: rgba(0,0,0,.03); } } }
