@import "../globals";
.boxSection {
    cursor: pointer;
    box-shadow: 0 0 6px $shadow !important;
    border-radius: 30px;
    background-color: #FFF;
    .imageContainer {
        padding: 7% 10% 0 10%; }
    .boxTitle {
        font-size: 1.2em;
        text-align: center;
        font-weight: bold;
        p {
            width: 80%;
            margin: 0 auto 8px auto; } }
    .boxFooter {
        padding: 0 15%;
        .boxButtonSection {
            margin-top: -20px;
            padding: .7em;
            &>div {
                padding: .3em !important; } }
        button {
            font-size: .8em;
            border-radius: 15px;
            padding-left: 0;
            padding-right: 0;
            margin-bottom: 15px; } } }
