@import '../util/globals';

.dashboardClass {
    flex: 1;
    display: flex;
    margin: 0 !important;
    max-width: 100%;
    .logoContainer {
        padding: 10px;
        cursor: pointer; }
    .classContainer {
        width: 100% !important; }
    & > div {
        flex: 1;
        display: flex !important; }
    .dashContainer {
        flex: 1;
        display: flex;
        max-width: 100%; }
    .sideMenu {
        width: 100px;
        display: flex;
        flex-direction: column;
        img {
            margin-top: 10px; }
        @media screen and (max-width:1023px) {
            display: none !important; } }
    .dashboardContent {
        flex: 1 0 0;
        overflow-x: hidden;
        overflow-y: hidden;
        box-shadow: 0 0 15px $shadow !important;
        background-size: cover;
        display: flex !important;
        flex-direction: column;
        @media screen and (min-width: 1024px) {
            border-top-left-radius: 85px;
            padding: 60px 60px 15px 60px !important; }
        @media screen and (max-width: 1023px) {
            padding: 30px 30px 15px 30px !important; } }
    .dashboardMainContent {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        padding: 1rem;
        &>div {
            margin-top: 20px !important; } } }

.areaStepImg {
    display: flow-root;
    img {
        float: right; } }

.setYScroll {
    overflow-y: auto; }

.areaStepDescription {
    font-size: 3.2em;
    text-align: right;
    .areaSub {
        max-width: 430px !important;
        float: right;
        font-size: .5em;
        color: $gray; } }
