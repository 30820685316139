@import '../util/globals';
.areaSection {
    margin: 0px !important;
    .frameworkConfiguration {
        h1 {
            color: $gray;
            font-weight: 500; }

        .textDescription {
            margin-bottom: 30px; }
        .FWSelection {
            margin-bottom: 30px; } }
    .flexed {
        flex: 1; } }
