.myTask {
    .dropzone {
        //flex: 1
        height: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        border-width: 2;
        border-radius: 2;
        border-color: #eeeeee;
        border-style: dashed;
        background-color: #fafafa;
        color: #bdbdbd;
        outline: none;
        transition: border .24s ease-in-out; } }
