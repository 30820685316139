.loginPage {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .labelComponentWhite {
        label {
            color: #FFF !important; } }
    .welcome {
        text-align: center;
        color: #FFF;
        font-weight: bolder;
        font-size: 1.8rem;
        letter-spacing: 10px; }
    .link {
        color: #fff;
        text-decoration: underline; }
    .back {
        color: #FFF;
        font-size: 1.1rem;
        letter-spacing: 2px;
        text-align: center;
        width: 100%;
        display: inline-block; }
    .loginError {
        input {
            border-color: #d20409 !important;
            border-width: 2px !important; } }
    .errorMessage {
        background-color: #d20409;
        color: #FFF;
        text-align: center !important;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        margin-bottom: -15px; } }
