@import "../globals";
.backgroundModal {
    background-color: $lighterGray !important;
    border-radius: 15px !important;
    padding: 1.5rem 3rem !important;
 }    //max-height: calc(70vh)
.mainModal {
    border-radius: 15px !important;
 }    //max-height: calc(70vh)

