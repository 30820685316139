@import '../util/globals';

.BPMGenerator {
    flex: 1;
    display: flex;
    flex-direction: column;
    .handleResize {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background-color: $blue; }
    .handleLineResize {
        border-color: $blue; }
    .bottomButtons {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        .buttonContainer {
            margin-left: auto;
            display: flex; } } }

