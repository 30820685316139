.registration {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .welcome {
        text-align: center;
        color: #FFF;
        font-weight: bolder;
        font-size: 1.8rem;
        letter-spacing: 10px;
        text-transform: uppercase; } }
