@import "../globals";
.textEvidence {
    flex: 1;
    display: flex;
    & > div {
        flex: 1;
        .selectedItem {
            background-color: $blueFocused !important;
            color: #FFF !important;
            div {
                color: #FFF !important; } } } }
