.platformConfiguration {
    .dropzone {
        margin-bottom: 15px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        border-width: 2;
        border-radius: 2;
        border-color: #eeeeee;
        border-style: dashed;
        background-color: #fafafa;
        color: #bdbdbd;
        outline: none;
        transition: border .24s ease-in-out;
        aspect-ratio: 1;
        .imageDisplay {
            flex: 1;
            aspect-ratio: 1;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat; } } }
