@import "../util/globals";
.userComponent {
    &> div {}
    box-shadow: 0 0 10px $shadow;
    background-color: #FFF;
    border-radius: 10px;
    border-width: 0;
    margin-bottom: 10px !important;
    .sub {
        margin-top: 8px !important; }
    .edit, .delete {
        cursor: pointer; }
    .delete {
        color: $red;
        text-decoration: underline;
        margin-left: 10px; } }
