.library {
    .tabContainer {
        & > div {
            height: 100%;
            display: flex;
            flex-direction: column;
            .tab {
                padding-bottom: 0;
                flex: 1;
                & > .grid {
                    height: 100%;
                    & > .row {
                        padding-bottom: 0; } } } } } }
