@import "../globals";

.simplixCollapsible {
    width: 100%;
    margin-bottom: .5rem;
    .sc_header {
        border-radius: 5px;
        background-color: $blue;
        color: #FFF;
        padding: 10px 0;
        cursor: pointer;
        user-select: none; }

    .sc_triggerName {
        margin-left: 10px;
        margin-right: 10px;
        &::after {
            content: "\002B";
            float: right; } }

    .sc_triggerName_open {
        &::after {
            content: "\2212";
            float: right; } }

    .sc_container {
        width: 100%;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
        background-color: #f1f1f1; }

    .sc_container_closed {
        max-height: 0; }

    .sc_container_open {
        max-height: none; } }

