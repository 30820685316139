.speedometerContainer {
    padding: 0;
    max-width: 300px;
    height: 200px;
    .label {
        text {
            &:first-child {
                transform: rotate(0deg) translate(-40%, 12%) !important; }
            &:last-child {
                transform: rotate(0deg) translate(40%, 12%) !important; } } } }
