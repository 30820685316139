.diagramList {
    flex: 1;
    display: flex;
    flex-direction: column;
    .gridContainer {
        flex: 1;
        display: flex;
        flex-direction: column; }
    .listContainer {
        flex: 1; } }
