$lighterGray:   #f1f1f1;
$lightGray:     #B1B1B1;
$gray:          #727176;
$darkBlue:      #001737;
$blue:          #0061FF;
$blue2:         #003180;
$blueFocused:   #044fc7;
$shadow:        #B1B1B1;
$red:           #b32e2e;
$darkGray:      #414243;
