@import '../util/globals';

.dashMenu {
    box-shadow: none !important;
    border-width: 0 !important;
    margin-top: 20px !important;
    overflow-y: scroll;
    max-height: 100%;
    .active {
        color: $blue !important;
        fill: $blue !important;
        background-color: rgba(255, 255, 255, 0) !important;
        &::after {
            content: '•••'; }
 }        //     font-size: 1.1vw
    a {
        color: #737375 !important;
        fill: #737375 !important;
        // padding: 0 1.15 !important
        // width: fit-content
        &:hover {
            fill: $blue !important;
            color: $blue !important;
            background-color: rgba(255, 255, 255, 0) !important; } }
    .itemName {
        font-size: .65em; } }
