@import "../../../util/globals.sass";
.partnerDashboard {
    .optionsIcon {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
        z-index: 100;
        color: $blue;
        .deleteButton {
            color: $red !important; } }
    .boxFramework {
        position: relative;
        cursor: pointer;
        box-shadow: 0 0 6px $shadow !important;
        border-radius: 30px;
        //aspect-ratio: 1
        height: 200px;
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        &:hover {
            border-style: solid;
            border-width: 3px;
            border-color: $blue;
            .fwContent {
                h1 {
                    color: $blue; }
                h2 {
                    color: $blue; } } }
        .BFMainContainer {
            margin: auto !important;
            width: 100%; }
        &::before {
            content: "";
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            border-radius: 30px;
            background-color: rgba(255, 255, 255, 0) !important; }
        .fwContent {
            color: $darkBlue;
            padding-left: 22px !important;
            padding-right: 22px !important;
            h1 {
                font-size: 3.5rem;
                margin: 0;
                width: 100%; }
            h2 {
                font-size: 2rem;
                margin: 0;
                color: $darkBlue;
                width: 100%;
                user-select: none !important;

                @media screen and (max-width: 1250px) {
                    font-size: 1.5rem;
 }                    //word-break: break-all



 } } } }                // word-break: normal
