@import "../globals";
.fileManager {
    .searchInputs {
        padding: 0 21px 0 21px !important; }
    .listContainer {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0;
        overflow-y: auto; }
    .labelActive {
        margin-top: 7px !important; }
    .fileList {
        user-select: none;
        .item {
            border-radius: 10px !important; } }
    .selectedItem {
        background-color: $blueFocused !important;
        color: #FFF !important;
        div {
            color: #FFF !important; } }
    .tabContainer {
        & > div {
            height: 100%;
            display: flex;
            flex-direction: column;
            .tab {
                padding-bottom: 0;
                flex: 1;
                & > .grid {
                    height: 100%;
                    & > .row {
                        padding-bottom: 0; } } } } }
    .filesContainer {
        height: 100%;
        & > div {
            height: 100%;
            // flex: 1
            // display: flex
            // flex-direction: column
            // .segment
            //     flex: 1
            //     display: flex
            //     flex-direction: column
            //     .grid
 } } }            //         flex: 1
