.users {
    .topBar {
        display: flex !important; }
    .dashTitle {
        margin: 0;
        @media screen and (max-width: 525px) {
            font-size: 35px !important; } }
    .buttonContainer {
        display: flex;
        a {
            margin-top: auto;
            margin-bottom: auto;
            width: 100%; }
        .buttonBlue {
            padding-top: .4em;
            padding-bottom: .4em;
            font-size: 1.2rem !important; } }
    .buttonNextToTitle {
        padding-left: 25px;
        @media screen and (max-width:768px) {
            display: none; } } }
