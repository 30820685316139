@import '../util/globals';

.DashHeader {
    background-image: linear-gradient(to right, $darkBlue, $blue);
    border-radius: 40px;
    color: #FFF;
    padding-left: 30px !important;
    padding-right: 30px !important;
    display: flex !important;
    .titleHeader {
        font-size: 2.3rem;
        @media screen and (max-width: 1240px) {
            font-size: 2.1rem !important; }
        @media screen and (max-width: 990px) {
            font-size: 1.9rem !important; }
        @media screen and (max-width: 768px) {
            font-size: 1.7rem !important; } }
    .language {
        align-self: center;
        img {
            height: 30px !important; } }
    .languageDropdown {
        margin-left: 10px;
        align-self: center; }
    .ascenter {
        align-self: center; }
    .headerSearch {
        flex: 1; }
    &> div {
        padding: 10px 0;
        margin-right: 10px; }
    button {
        background-color: rgba(0, 0, 0, 0) !important;
        color: #FFF !important;
        padding: 0 !important;
        font-size: 2.2em !important; }
    .iconHeader {
        display: flex;
        cursor: pointer;
        img {
            height: 30px; }
        .logout {
            margin-top: 4px;
            margin-left: 5px; }
        svg {
            height: 30px;
            fill: #FFF;
            margin: auto; }
 }        //height: 90px
    .until1024 {
        @media screen and (max-width: 1023px) {
            display: none; } }
    .upTo1024 {
        @media screen and (min-width: 1024px) {
            display: none; } } }

.mobileMenuContainer {
    // padding-top: 0px !important
    // padding-left:
    padding: 0 !important;
    margin: 0 35px 14px 35px !important; }

.headerUser {
    margin-left: 4em;
    margin-right: 2em;
    div {
        color: #FFF !important; } }

.userSection {
    button {
        background-color: rgba(0, 0, 0, 0) !important;
        color: #FFF !important;
        padding: 0 !important;
        font-size: 2.2em !important; } }
